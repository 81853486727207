import { Page } from "@/types";
import { api } from "@/util/axios";
import { Module } from "vuex";

const actions = {
  add_order: "ADD_ORDER",
  set_orders: "SET_ORDERS",
  remove_order: "REMOVE_ORDER",
  set_neworder_count: "SET_NEWORDER_COUNT",
};

type OrderState = {
  orderPage: Page<any>;
  newOrderCount: number;
};

const order: Module<OrderState, unknown> = {
  namespaced: true,
  state: () => ({
    orderPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
    newOrderCount: 0,
  }),
  getters: {
    orderPage: (state) => state.orderPage,
    getOrder: (state) => (orderId: string) => {
      return state.orderPage.docs.filter((item) => item._id == orderId)[0];
    },
    getNewOrderCount: (state) => state.newOrderCount,
  },
  mutations: {
    [actions.add_order](state, _order) {
      const order = state.orderPage.docs.filter(
        (item) => item._id == _order._id
      );
      if (order.length > 0) {
        state.orderPage.docs = state.orderPage.docs.map((item: any) =>
          item._id === _order._id ? _order : item
        );
      } else {
        state.orderPage.docs.push(_order);
      }
    },
    [actions.set_orders](state, _orders) {
      state.orderPage = _orders;
    },
    [actions.remove_order](state, _order) {
      state.orderPage.docs = state.orderPage.docs.filter((order) => {
        return order._id !== _order._id;
      });
    },
    [actions.set_neworder_count](state, count) {
      state.newOrderCount = count;
    },
  },
  actions: {
    async fetchOrder(context, params = "") {
      return await api
        .get(`/v1/order${params}`)
        .then((response) => {
          context.commit("ADD_ORDER", response.data.order);
          return response.data.order;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },

    async retryAppointmentPayment(context, payload) {
      return await api
        .put(`/v1/order/retry-appointment-payment/${payload.id}`, {
          payment: payload.payment,
        })
        .then((response) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Processing Payment",
            },
            { root: true }
          );
          return response.data;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async countNewOrders(context, params = "") {
      await api.get(`/v1/order/unreadOrders${params}`).then((response) => {
        const { unreadOrders = 0 } = response.data;
        context.commit(actions.set_neworder_count, unreadOrders);
      });
    },
    async fetchOrderList(context, params = "") {
      return await api
        .get(`/v1/order${params}`)
        .then((response) => {
          const { order = null, orderPage = null } = response.data;
          if (orderPage) {
            context.commit(actions.set_orders, orderPage);
          }

          if (order) {
            context.commit(actions.add_order, order);
          }

          return response;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
          return error;
        });
    },

    async createOrder(context, payload) {
      return await api
        .post(`/v1/order`, { ...payload })
        .then((response) => {
          context.commit(actions.add_order, response.data.order);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Order Created",
            },
            { root: true }
          );
          return response.data.order;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateOrder(context, payload) {
      const { orderId, businessId, ...orderdata } = payload;
      return await api
        .put(`/v1/order/${orderId}`, { ...orderdata })
        .then((response) => {
          context.commit(actions.add_order, response.data.order);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Order Updated",
            },
            { root: true }
          );
          return response.data.order;
        })
        .catch((error) => {
          console.log("Order Error", error);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },

    async fulfilOrder(context, payload) {
      return await api
        .post(`/v1/order/fulfil/${payload.id}`, { ...payload.data })
        .then((response) => {
          context.commit(actions.add_order, response.data.order);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Order Status Updated",
            },
            { root: true }
          );
          return response.data.order;
        })
        .catch((error) => {
          console.log("Order Error", error);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },

    async checkOrderPayment(context, payload) {
      return await api
        .post(`/v1/order/${payload.id}/payment-status`, { ...payload.data })
        .then((response) => {
          context.commit(actions.add_order, response.data.order);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Order Status Updated",
            },
            { root: true }
          );
          return response.data.order;
        })
        .catch((error) => {
          console.log("Order Error", error);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },

    async retryOrderPayment(context, payload) {
      return await api.put(`/v1/order/${payload.id}/retry-payment`, {
        payment: payload.payment,
      });
    },

    async refundOrder(context, payload) {
      return await api
        .post(`/v1/order/${payload.id}/refund`)
        .then((response) => {
          context.commit(actions.add_order, response.data.order);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Order Payment Refund Initialized",
            },
            { root: true }
          );
          return response.data.order;
        })
        .catch((error) => {
          console.log("Order Error", error);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },

    async updateStatus(context, payload: { id: string; status: string }) {
      return await api
        .put(`/v1/order/status/${payload.id}`, { status: payload.status })
        .then((response) => {
          context.commit(actions.add_order, response.data.order);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Order Status Updated",
            },
            { root: true }
          );
          return response.data.order;
        })
        .catch((error) => {
          console.log("Order Error", error);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async dropOrder(context, orderId = "") {
      return await api
        .delete(`/v1/order/${orderId}`)
        .then((response) => {
          context.commit(actions.remove_order, response.data?.order);
          context.dispatch(
            "setToast",
            {
              title: "Deleted",
              type: "success",
              text: "Order Deleted!",
            },
            { root: true }
          );
        })
        .catch((error) => {
          console.log("Order Error", error);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default order;
